import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { EnrollmentTransfer, LessonTransfer, materialService, RecipeTransfer } from "../service";
import { SubmissionList } from "./list";

interface Review {
    username: string;
    recipe: number;
}

interface RegistrationInfoParams {
    username: string;
    chapter?: number;
}

interface RecipeTableParams {
    lesson?: LessonTransfer;
    enrollments?: EnrollmentTransfer[];
}

const RegistrationInfo: FunctionComponent<RegistrationInfoParams> = (params) => {
    const [registered, setRegistered] = useState(false);

    const check = useCallback(async () => {
        if (params.username && params.chapter) {
            const response = await materialService().get(`/registration/${params.username},${params.chapter}`);
            switch (response.status) {
                case 200:
                    setRegistered(true);
                    break;
            }
        }
    }, [params.username, params.chapter]);

    useEffect(() => {
        check();
    }, [check]);

    return (
        <td>
            {
                registered ?
                    <Badge variant="success" className="ml-2">正常</Badge>
                    :
                    <Badge variant="danger" className="ml-2">错误</Badge>
            }
        </td>
    );
}

export const RecipeTable: FunctionComponent<RecipeTableParams> = (params) => {
    const [review, setReview] = useState<Review>();

    const sections = params.lesson?.chapter?.sections || [];
    const recipes = ([] as RecipeTransfer[]).concat(sections, params.lesson?.prerequisites || [], params.lesson?.relatives || []);

    return (
        <>
            <Table bordered hover responsive size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>用户</th>
                        <th>权限</th>
                        {
                            recipes.map(recipe =>
                                <th key={recipe.id}>
                                    {recipe.title}
                                </th>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        params.enrollments?.map(enrollment =>
                            <tr key={enrollment.user}>
                                <td>
                                    {enrollment.user}
                                </td>
                                <RegistrationInfo username={enrollment.user} chapter={params.lesson?.chapter?.course?.id} />
                                {
                                    recipes.map(recipe =>
                                        <td key={recipe.id}>
                                            <Button
                                                variant={review?.username === enrollment.user && review?.recipe === recipe.id ? "primary" : "link"}
                                                size="sm"
                                                style={{ textDecoration: "none" }}
                                                className="pt-0 pb-0"
                                                onClick={() => setReview({ username: enrollment.user, recipe: recipe.id })}
                                            >
                                                查看
                                            </Button>
                                        </td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            <SubmissionList recipe={review?.recipe} username={review?.username} />
        </>
    );
}