import React, { FunctionComponent, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { SubmissionTransfer } from "../service";

interface CommandProps {
    submission: SubmissionTransfer;
    remove(id: string): void;
}

export const RemoveCommand: FunctionComponent<CommandProps> = (params) => {
    const [show, setShow] = useState(false);

    const confirm = () => {
        params.remove(params.submission.id);

        setShow(false);
    }

    const cancel = () => {
        setShow(false);
    }

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="reset">删除</Tooltip>}
            >
                <Button variant="light" type="submit" onClick={() => setShow(true)}>
                    <AiOutlineDelete size="20" />
                </Button>
            </OverlayTrigger>

            <Modal show={show} onHide={cancel}>
                <Modal.Header closeButton>
                    <Modal.Title>确认</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    代码执行数据删除以后不可恢复！你确认吗？
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancel}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={confirm}>
                        确认
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}