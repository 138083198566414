import React, { FormEvent, FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import { SubmissionTransfer } from "../service";
import { RemoveCommand } from "./remove";
import { GradeCommand } from "./grade";

interface CommandPanelParams {
    submission: SubmissionTransfer;
    grade(submission: SubmissionTransfer, value: number): void;
    remove(id: string): void;
}

const CommandSeparator: FunctionComponent = () => {
    return (
        <span className="ml-2 mr-2"></span>
    );
}

export const CommandPanel: FunctionComponent<CommandPanelParams> = (params) => {
    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    }

    const reset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <Form onSubmit={submit} onReset={reset}>
            <GradeCommand
                submission={params.submission}
                grade={params.grade}
            />

            <CommandSeparator />

            <RemoveCommand
                submission={params.submission}
                remove={params.remove}
            />
        </Form>
    );
}