import axios, { CancelTokenSource } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Container, Jumbotron, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { accountService, TenantTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { TenantCreate } from "./tenantcreate";

let source: CancelTokenSource;

export const TenantList: FunctionComponent = () => {
    const dispatch = useDispatch();

    const [tenants, setTenants] = useState<TenantTransfer[]>();
    const [show, setShow] = useState<boolean>(false);

    const getTenants = useCallback(async () => {
        const source = axios.CancelToken.source();
        const response = await accountService().get(`/tenant`, {
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                setTenants(response.data as TenantTransfer[]);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [dispatch]);

    useEffect(() => {
        getTenants();

        return () => {
            source?.cancel();
        }
    }, [getTenants]);

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>机构</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Button variant="primary" onClick={() => setShow(true)}>
                    新建
                </Button>

                <TenantCreate
                    show={show}
                    onExit={() => { setShow(false); getTenants(); }}
                />

                <Table bordered hover size="sm" className="mt-4 mb-4">
                    <thead>
                        <tr>
                            <th>编号</th>
                            <th>代号</th>
                            <th>名称</th>
                            <th>校长</th>
                            <th>登记</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tenants?.map(tenant =>
                                <tr key={tenant.id}>
                                    <td>
                                        {tenant.id}
                                    </td>
                                    <td>
                                        <Link to={`/tenant/${tenant.code}`} style={{ textDecoration: "none" }}>
                                            {tenant.code}
                                        </Link>
                                    </td>
                                    <td>
                                        {tenant.fullname}
                                    </td>
                                    <td>
                                        <Link to={`/user/${tenant.principal}`} style={{ textDecoration: "none" }}>
                                            {tenant.principal}
                                        </Link>
                                    </td>
                                    <td>
                                        {tenant.created}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <TutorFooter />
            </Container>
        </>
    );
}