import React, { FunctionComponent } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { CircleBasic } from "./circlebasic";
import { CircleEnrollment } from "./enrollment";
import { LessonList } from "../lesson";

interface RouteParams {
    id: string
}

export const CircleDetail: FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>班级</h1>
                </Container>
            </Jumbotron>
            <Container>
                <CircleBasic id={params.id} />

                {
                    params.id !== "0" &&
                    <CircleEnrollment id={params.id} />
                }

                {
                    params.id !== "0" &&
                    <LessonList id={params.id} />
                }

                <TutorFooter />
            </Container>
        </>
    );
}