import axios, { CancelTokenSource } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Container, Jumbotron, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { accountService, CodeTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { CodeCreate } from "./codecreate";
import { CodeRemove } from "./coderemove";

let source: CancelTokenSource;

export const CodeList: FunctionComponent = () => {
    const dispatch = useDispatch();

    const [codes, setCodes] = useState<CodeTransfer[]>();
    const [create, setCreate] = useState<boolean>(false);
    const [remove, setRemove] = useState<number>();

    const getCodes = useCallback(async () => {
        const source = axios.CancelToken.source();
        const response = await accountService().get(`/code`, {
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                setCodes(response.data as CodeTransfer[]);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [dispatch]);

    useEffect(() => {
        getCodes();

        return () => {
            source?.cancel();
        }
    }, [getCodes]);

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>邀请</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Button variant="primary" onClick={() => setCreate(true)}>
                    新建
                </Button>

                <CodeCreate
                    show={create}
                    onExit={() => { setCreate(false); getCodes(); }}
                />

                <CodeRemove
                    id={remove}
                    onExit={() => { setRemove(undefined); getCodes(); }}
                />

                <Table bordered hover size="sm" className="mt-4 mb-4">
                    <thead>
                        <tr>
                            <th>编号</th>
                            <th>邀请码</th>
                            <th>次数</th>
                            <th>创建者</th>
                            <th>有效期</th>
                            <th>注释</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            codes?.map(code =>
                                <tr key={code.id}>
                                    <td>
                                        {code.id}
                                    </td>
                                    <td>
                                        <Link to={`/code/${code.id}`} style={{ textDecoration: "none" }}>
                                            {code.code}
                                        </Link>
                                    </td>
                                    <td>
                                        {code.count}
                                    </td>
                                    <td>
                                        <Link to={`/user/${code.creator}`} style={{ textDecoration: "none" }}>
                                            {code.creator}
                                        </Link>
                                    </td>
                                    <td>
                                        {code.expiration}
                                    </td>
                                    <td>
                                        {code.comment}
                                    </td>
                                    <td>
                                        <Button
                                            size="sm"
                                            className="pt-0 pb-0 ml-1 mr-1"
                                            onClick={() => setRemove(code.id)}
                                        >
                                            移除
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <TutorFooter />
            </Container>
        </>
    );
}