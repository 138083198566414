import Ansi from "ansi-to-react";
import React, { FunctionComponent } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { ExecuteInput, ExecuteReply, ExecuteResult, Message, MessageStatus, Stream } from "../message";
import { SubmissionTransfer } from "../service";
import { CommandPanel } from "./command";
import { SubmissionMeta } from "./meta";

interface SubmissionParams {
    submission: SubmissionTransfer,
    remove(id?: string): void;
    grade(submission: SubmissionTransfer, value: number): void;
}

interface MessageParams {
    snippet?: string,
    message?: Message,
}

interface MessagesParams {
    snippet?: string,
    messages?: Message[],
}

const ReplySection: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteReply;

    switch (content?.status) {
        case MessageStatus.error:
            return (
                <pre>
                    <Ansi>
                        {content?.traceback.join("\n")}
                    </Ansi>
                </pre>
            );
        default:
            return (
                <>
                </>
            );
    }
}

const InputSection: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteInput;
    const code = content?.code || "";

    return (
        <CodeMirror
            className="mt-4 mb-4"
            value={code}
            options={{
                mode: "python",
                theme: "eclipse",
                lineNumbers: true,
                lineWrapping: true,
                indentUnit: 4,
                autoRefresh: true,
                readOnly: true,
            }}
            onBeforeChange={(editor, data, python) => {
            }}
        />
    );
}

const ResultSection: FunctionComponent<MessageParams> = (params) => {
    const content = params.message?.content as ExecuteResult;

    return (
        <>
            {
                content &&
                <pre className="mt-4">
                    {
                        JSON.stringify(content?.data, null, 2)
                    }
                </pre>
            }
        </>
    );
}

const StreamSection: FunctionComponent<MessagesParams> = (params) => {
    const content = params.messages?.map(message =>
        JSON.stringify((message.content as Stream).text)
    ).reduce((accumulator, current) => accumulator + "\n" + current, "");

    return (
        <>
            {
                content &&
                <pre className="mt-4">
                    <Ansi>
                        {
                            content
                        }
                    </Ansi>
                </pre>
            }
        </>
    );
}

export const Submission: FunctionComponent<SubmissionParams> = (params) => {
    return (
        <>
            <SubmissionMeta submission={params.submission} />

            <CommandPanel
                submission={params.submission}
                remove={params.remove}
                grade={params.grade}
            />

            <InputSection
                message={params.submission?.execute_input}
                snippet={params.submission?.snippet}
            />

            <ReplySection
                message={params.submission?.execute_reply}
            />

            <ResultSection
                message={params.submission?.execute_result}
            />

            <StreamSection
                messages={params.submission?.stream}
                snippet={params.submission?.snippet}
            />
        </>
    );
}