import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    user: UserTransfer,
    show: boolean,
    onExit: () => void,
}

export const RoleEdit: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [user, setUser] = useState<UserTransfer>(params.user);
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(params.show)
        setUser(params.user);
    }, [params.show, params.user]);

    const select = (event: ChangeEvent<HTMLSelectElement>) => {
        const { name, selectedOptions } = event.target;

        setUser({
            ...user,
            [name]: Array.from(selectedOptions).map(option => option.value),
        });
    };

    const check = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        setUser({
            ...user,
            [name]: checked,
        });
    };

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSpin(true);

        const response = await accountService().put(`/user`, user);

        setSpin(false);
        setShow(false);

        switch (response.status) {
            case 200:
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `意外错误：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    };

    return (
        <>
            <Modal show={show} size="lg" onExit={() => params.onExit()}>
                <Modal.Header>
                    <Modal.Title>编辑</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate onSubmit={update} id="form">
                        <Row>
                            <Form.Group as={Col} controlId="id">
                                <Form.Label>编号</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={user.id}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="active">
                                <Form.Label>状态</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    label="账号活跃"
                                    checked={user.active === null || user.active === true}
                                    name="active"
                                    onChange={check}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="roles">
                                <Form.Label>角色</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="roles"
                                    value={user.roles}
                                    multiple
                                    onChange={select}
                                >
                                    <option value="student">学生</option>
                                    <option value="tutor">导师</option>
                                    <option value="principal">校长</option>
                                    <option value="admin">管理员</option>
                                </Form.Control>
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" form="form" disabled={spin}>
                        {
                            spin && <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                        }
                        确认
                    </Button>

                    <Button variant="outline-secondary" type="cancel" onClick={() => setShow(false)}>
                        取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}