import axios from "axios";
import moment from "moment";
import React, { ChangeEvent, FormEvent, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { accountService, CourseTransfer, materialService, RegistrationTransfer, UserTransfer } from "../service";
import { AppStore } from "../store";
import { RegistrationActionType, RegistrationContext } from "./regcontext";

const RegistrationBuilder: FunctionComponent = () => {
    const passport = useSelector((store: AppStore) => store.passport);
    const { store, dispatch } = useContext(RegistrationContext);

    const [validated, setValidated] = useState(false);
    const [users, setUsers] = useState<UserTransfer[]>();
    const [courses, setCourses] = useState<CourseTransfer[]>();
    const [registration, setRegistration] = useState<Partial<RegistrationTransfer>>();

    const abort = useRef<AbortController>();
    const cancel = useRef<AbortController>();

    const getCourses = useCallback(async () => {
        console.log("Get all courses");

        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/course`, {
                signal: abort.current.signal,
            });
            switch (response.status) {
                case 200:
                    setCourses(response.data as CourseTransfer[]);
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in material service request", error);
            }
        }
    }, []);

    const getUsers = useCallback(async () => {
        console.log("Get all usernames");

        try {
            cancel.current = new AbortController();
            const response = await accountService().get(`/user`, {
                signal: cancel.current.signal,
                params: {
                    active: true
                }
            });
            switch (response.status) {
                case 200:
                    setUsers(response.data as UserTransfer[]);
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }
    }, []);

    useEffect(() => {
        getUsers();
        getCourses();
    }, [getCourses, getUsers]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
            cancel.current?.abort();
        }
    }, []);

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setRegistration({
            ...registration,
            [name]: value.trim(),
        });
    };

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: RegistrationActionType.START_SPIN });

        if (event.currentTarget.checkValidity()) {
            try {
                cancel.current = new AbortController();
                const response = await materialService().post(`/registration`, registration);

                switch (response.status) {
                    case 200:
                        dispatch({ type: RegistrationActionType.HIDE_BUILDER });
                        dispatch({ type: RegistrationActionType.START_REFRESH });
                        break;
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    // this should be fine
                } else {
                    console.log("Error found in account service request", error);
                }
            }
        }

        dispatch({ type: RegistrationActionType.STOP_SPIN });

        setValidated(true);
    };

    const initialize = () => {
        setRegistration({
            username: "",
            course_id: 0,
            course_title: "",
            time: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
            operator: passport.username,
        });
        setValidated(false);
    };

    const finalize = () => {
    }

    return (
        <Modal show={store.builder} onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>
                    注册
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={update} id="form">
                    <Row>
                        <Form.Group as={Col} controlId="id">
                            <Form.Label>编号</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={registration?.username + " - " + registration?.course_id}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="time">
                            <Form.Label>日期</Form.Label>
                            <Form.Control
                                type="date"
                                readOnly
                                name="time"
                                value={registration?.time?.substring(0, registration.time.indexOf("T"))}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="username">
                            <Form.Label>用户</Form.Label>
                            <Typeahead
                                id="typeahead-username"
                                labelKey={(user) => `${user.username} / ${user.fullname}`}
                                onChange={selected => {
                                    setRegistration({
                                        ...registration,
                                        username: selected.length === 0 ? "" : selected[0].username,
                                    });
                                }}
                                options={users || []}
                                inputProps={{ required: true }}
                                selected={users?.filter(data => data.username === registration?.username) || []}
                                placeholder="用户名"
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="course">
                            <Form.Label>课程</Form.Label>
                            <Typeahead
                                id="typeahead-course"
                                labelKey={(course) => `${course.id} / ${course.title}`}
                                onChange={selected => {
                                    setRegistration({
                                        ...registration,
                                        course_id: selected.length === 0 ? 0 : selected[0].id,
                                    });
                                }}
                                options={courses || []}
                                inputProps={{ required: true }}
                                selected={courses?.filter(data => data.id === registration?.course_id) || []}
                                placeholder="课程"
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="operator">
                            <Form.Label>操作</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                name="operator"
                                value={registration?.operator}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form">
                    {
                        store.spin && <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                    }
                    确定
                </Button>

                <Button variant="outline-secondary" type="cancel" onClick={() => dispatch({ type: RegistrationActionType.HIDE_BUILDER })}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { RegistrationBuilder };
