import React, { ChangeEvent, FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { accountService, TenantTransfer, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean,
    onExit: () => void,
}

export const TenantCreate: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [tenant, setTenant] = useState<Partial<TenantTransfer>>();
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [principals, setPrincipals] = useState<UserTransfer[]>();

    const getPrincipals = useCallback(async () => {
        const response = await accountService().get(`/user?role=principal`);

        switch (response.status) {
            case 200:
                setPrincipals(response.data as UserTransfer[]);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "⚠️",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [dispatch]);

    useEffect(() => {
        getPrincipals();
    }, [getPrincipals]);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
        setTenant({
            created: new Date()
        });
        setValidated(false);
    };

    const finalize = () => {
        params.onExit();
    }

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setTenant({
            ...tenant,
            [name]: value.trim(),
        });
    };

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = await accountService().post(`/tenant`, tenant);

            setSpin(false);
            setShow(false);

            switch (response.status) {
                case 200:
                    break;
                case 409:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "😞",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `机构代码已存在或者校长已经拥有机构了。`,
                    });
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `意外错误：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } else {
            setValidated(true);
        }
    };

    return (
        <>
            <Modal show={show} onEnter={initialize} onExited={finalize}>
                <Modal.Header>
                    <Modal.Title>
                        学生
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={update} id="form">
                        <Row>
                            <Form.Group as={Col} controlId="principal">
                                <Form.Label>校长</Form.Label>
                                <Typeahead
                                    id="principal"
                                    labelKey={(item) => `${item.username} / ${item.fullname}`}
                                    onChange={selected => {
                                        setTenant({
                                            ...tenant,
                                            principal: selected.length === 0 ? "" : selected[0].username,
                                        });
                                    }}
                                    options={principals || []}
                                    inputProps={{ required: true }}
                                    selected={principals?.filter(item => item.username === tenant?.principal) || []}
                                    placeholder="校长"
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="code">
                                <Form.Label>代号</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="code"
                                    value={tenant?.code || ""}
                                    required
                                    pattern="[a-z]+"
                                    minLength={4}
                                    maxLength={30}
                                    onChange={change}
                                />
                                <Form.Control.Feedback type="invalid">
                                    需要4个以上的英文大小写字母
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="fullname">
                                <Form.Label>名称</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullname"
                                    pattern="[A-Za-z\u3400-\u4DBF\u4E00-\u9FFF_]+"
                                    minLength={4}
                                    maxLength={30}
                                    value={tenant?.fullname || ""}
                                    onChange={change}
                                />
                            </Form.Group>
                            <Form.Control.Feedback type="invalid">
                                机构名要在4至30个字符之间
                            </Form.Control.Feedback>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" form="form">
                        {
                            spin && <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                        }
                        确定
                    </Button>

                    <Button variant="outline-secondary" type="cancel" onClick={() => setShow(false)}>
                        取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}