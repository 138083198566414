import React, { FunctionComponent, useEffect, useState } from "react";
import { Badge, Container } from "react-bootstrap";
import { accountService, materialService, notebookService } from "../service";

interface HealthInfo {
    status: string;
}

export const ServiceStatusComponent: FunctionComponent = () => {

    const [accountStatus, setAccountStatus] = useState<boolean>();
    const [contentStatus, setContentStatus] = useState<boolean>();
    const [notebookStatus, setNotebookStatus] = useState<boolean>();

    useEffect(() => {
        const getAccountStatus = async () => {
            const response = await accountService().get(`/actuator/health`);

            switch (response.status) {
                case 200:
                    const info = response.data as HealthInfo;
                    setAccountStatus(info.status === "UP");

                    break;
            }
        }
        getAccountStatus();

        const getContentStatus = async () => {
            const response = await materialService().get(`/actuator/health`);

            switch (response.status) {
                case 200:
                    const info = response.data as HealthInfo;
                    setContentStatus(info.status === "UP");

                    break;
            }
        }
        getContentStatus();

        const getNotebookStatus = async () => {
            const response = await notebookService().get(`/actuator/health`);

            switch (response.status) {
                case 200:
                    const info = response.data as HealthInfo;
                    setNotebookStatus(info.status === "UP");

                    break;
            }
        }
        getNotebookStatus();

    }, []);


    return (
        <>
            <Container className="mt-5 mb-5">
                <h2 className="mt-4 mb-4">服务状态</h2>

                <p>身份服务：<Badge variant={accountStatus ? "success" : "danger"}>{accountStatus ? "在线" : "下线"}</Badge></p>
                <p>内容服务：<Badge variant={contentStatus ? "success" : "danger"}>{contentStatus ? "在线" : "下线"}</Badge></p>
                <p>手册服务：<Badge variant={notebookStatus ? "success" : "danger"}>{notebookStatus ? "在线" : "下线"}</Badge></p>

            </Container>
        </>
    )

}