import React, { FunctionComponent } from "react";

interface CaptionParams {
    title: string;
}

export const SectionCaption: FunctionComponent<CaptionParams> = (props) => {
    return (
        <h2 className="mt-5 mb-5">
            {props.title}
        </h2>
    );
}