import React, { FunctionComponent } from "react";
import { Container, Jumbotron, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { CodeBasic } from "./codebasic";

interface RouteParams {
    id: string
}

export const CodeDetail: FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>邀请</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <CodeBasic
                            id={params.id}
                        />
                    </Tab>
                </Tabs>

                <TutorFooter />
            </Container>
        </>
    );
}