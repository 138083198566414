import axios, { CancelTokenSource } from "axios";
import moment from "moment-timezone";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Badge, Button, Container, Jumbotron, Pagination, Table } from "react-bootstrap";
import { VscLock } from "react-icons/vsc";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { accountService, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { BasicEdit } from "./basicedit";

interface UserListInfo {
    content: UserTransfer[];
    totalPages: number;
    totalElements: number;
    number: number;
    size: number;
}

let source: CancelTokenSource;

export const UserList: FunctionComponent = () => {
    const dispatch = useDispatch();

    const [users, setUsers] = useState<UserListInfo>();
    const [basic, setBasic] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [pages, setPages] = useState<number[]>();

    const getUsers = useCallback(async () => {
        const source = axios.CancelToken.source();
        const response = await accountService().get(`/user`, {
            params: {
                page: page,
            },
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                // collect users information
                const info = response.data as UserListInfo;
                setUsers(info);

                // collect series for pagination
                var series = [];
                for (var i = 1; i <= info.totalPages; i++) {
                    series.push(i);
                }
                setPages(series);

                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [page, dispatch]);

    useEffect(() => {
        getUsers();

        return () => {
            source?.cancel();
        }
    }, [getUsers]);

    const navigate = (value: number) => {
        setPage(value - 1);
    }

    const badge = (role: string) => {
        switch (role) {
            case "user":
                return "用户";
            case "tutor":
                return "导师";
            case "principal":
                return "校长";
            case "admin":
                return "管理员";
        }
    }

    const color = (role: string) => {
        switch (role) {
            case "user":
                return "primary";
            case "tutor":
                return "success";
            case "principal":
                return "warning";
            case "admin":
                return "danger";
        }
    }

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>用户</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Button variant="primary" onClick={() => setBasic(true)}>
                    新建
                </Button>

                <BasicEdit
                    show={basic}
                    user={{
                        id: 0,
                        username: "",
                        roles: ["student"],
                        dateCreated: moment().format('YYYY-MM-DD'),
                        dateExpired: moment().add(7, "d").format('YYYY-MM-DD'),
                    }}
                    onExit={() => { setBasic(false); getUsers(); }}
                />

                <Table bordered hover size="sm" className="mt-4 mb-4">
                    <thead>
                        <tr>
                            <th>用户名</th>
                            <th>全名</th>
                            <th>创建</th>
                            <th>到期</th>
                            <th>权限</th>
                            <th>渠道</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users?.content.map(item =>
                                <tr key={item.id}>
                                    <td>
                                        <Link to={`/user/${item.username}`} style={{ textDecoration: "none" }}>
                                            {item.username}
                                        </Link>
                                        {
                                            item.active === false && <VscLock className="ml-2" />
                                        }
                                    </td>
                                    <td>{item.fullname}</td>
                                    <td>{item.dateCreated}</td>
                                    <td>{item.dateExpired}</td>
                                    <td>
                                        {
                                            item.roles?.map(role =>
                                                <Badge key={role} variant={color(role)} className="mr-1">
                                                    {badge(role)}
                                                </Badge>
                                            )
                                        }
                                    </td>
                                    <td>{item.channel}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>

                <Pagination >
                    {
                        pages?.map(value =>
                            <Pagination.Item key={value} active={value === page + 1} onClick={() => navigate(value)}>
                                {value}
                            </Pagination.Item>
                        )
                    }
                </Pagination>

                <TutorFooter />
            </Container>
        </>
    );
}