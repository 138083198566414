import { createContext, Dispatch, Reducer } from "react";

export enum RegistrationActionType {
    SHOW_BUILDER,
    HIDE_BUILDER,
    SHOW_REMOVER,
    HIDE_REMOVER,
    START_SPIN,
    STOP_SPIN,
    START_REFRESH,
    STOP_REFRESH,
}

export interface ShowBuilderAction {
    type: typeof RegistrationActionType.SHOW_BUILDER;
}

export interface HideBuilderAction {
    type: typeof RegistrationActionType.HIDE_BUILDER;
}

export interface ShowRemoverAction {
    type: typeof RegistrationActionType.SHOW_REMOVER;
    victim: string;
}

export interface HideRemoverAction {
    type: typeof RegistrationActionType.HIDE_REMOVER;
}

export interface StartSpinAction {
    type: typeof RegistrationActionType.START_SPIN;
}

export interface StopSpinAction {
    type: typeof RegistrationActionType.STOP_SPIN;
}

export interface StartRefreshAction {
    type: typeof RegistrationActionType.START_REFRESH;
}

export interface StopRefreshAction {
    type: typeof RegistrationActionType.STOP_REFRESH;
}

export type RegistrationAction =
    ShowBuilderAction | HideBuilderAction |
    ShowRemoverAction | HideRemoverAction |
    StartSpinAction | StopSpinAction |
    StartRefreshAction | StopRefreshAction;

export interface RegistrationStore {
    builder: boolean;
    remover: boolean;
    spin: boolean;
    victim?: string;
    refresh: boolean;
}

const registrationReducer: Reducer<RegistrationStore, RegistrationAction> = (state, action) => {
    switch (action.type) {
        case RegistrationActionType.SHOW_BUILDER:
            return {
                ...state,
                builder: true,
            };

        case RegistrationActionType.HIDE_BUILDER:
            return {
                ...state,
                builder: false,
            }

        case RegistrationActionType.SHOW_REMOVER:
            return {
                ...state,
                victim: action.victim,
                remover: true,
            };

        case RegistrationActionType.HIDE_REMOVER:
            return {
                ...state,
                victim: undefined,
                remover: false,
                refresh: true,
            }

        case RegistrationActionType.START_SPIN:
            return {
                ...state,
                spin: true,
            }

        case RegistrationActionType.STOP_SPIN:
            return {
                ...state,
                spin: false,
            }

        case RegistrationActionType.START_REFRESH:
            return {
                ...state,
                refresh: true,
            }

        case RegistrationActionType.STOP_REFRESH:
            return {
                ...state,
                refresh: false,
            }
    }
}

interface ContextParams {
    store: RegistrationStore;
    dispatch: Dispatch<RegistrationAction>
}

const RegistrationContext = createContext<ContextParams>({
    store: {
        builder: false,
        remover: false,
        spin: false,
        victim: undefined,
        refresh: false,
    },
    dispatch: () => undefined,
})

export { RegistrationContext, registrationReducer };
