import axios from "axios";
import React, { FunctionComponent, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Button, Container, Jumbotron, Pagination, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { materialService, RegistrationTransfer } from "../service";
import { RegistrationBuilder } from "./regbuilder";
import { RegistrationActionType, RegistrationContext, registrationReducer } from "./regcontext";
import { RegistrationRemover } from "./regremover";

interface RegistrationListInfo {
    content: RegistrationTransfer[];
    totalPages: number;
    totalElements: number;
    number: number;
    size: number;
}

const RegistrationList: FunctionComponent = () => {
    const [registrations, setRegistrations] = useState<RegistrationListInfo>();
    const [page, setPage] = useState<number>(0);
    const [pages, setPages] = useState<number[]>();

    const abort = useRef<AbortController>();

    const [store, dispatch] = useReducer(registrationReducer, {
        builder: false,
        remover: false,
        spin: false,
        refresh: true,
    });

    const getRegistrations = useCallback(async () => {
        try {
            abort.current = new AbortController();
            const response = await materialService().get(`/registration`, {
                signal: abort.current.signal,
                params: {
                    page: page
                },
            });

            switch (response.status) {
                case 200:
                    // collect registration information
                    const info = response.data as RegistrationListInfo;
                    setRegistrations(info);

                    // collect series for pagination
                    var series = [];
                    for (var i = 1; i <= info.totalPages; i++) {
                        series.push(i);
                    }
                    setPages(series);

                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in material service request", error);
            }
        }
    }, [page]);

    const navigate = (value: number) => {
        setPage(value - 1);
    }

    const id = (registration: RegistrationTransfer) => {
        return registration.username + "," + registration.course_id;
    }

    useEffect(() => {
        if (store.refresh) {
            getRegistrations();

            dispatch({ type: RegistrationActionType.STOP_REFRESH });
        }
    }, [getRegistrations, store.refresh]);

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>课程</h1>
                </Container>
            </Jumbotron>
            <Container>
                <RegistrationContext.Provider value={{ store, dispatch }}>
                    <Button variant="primary" onClick={() => dispatch({
                        type: RegistrationActionType.SHOW_BUILDER
                    })}>
                        新建
                    </Button>

                    <RegistrationRemover />
                    <RegistrationBuilder />

                    <Table bordered hover size="sm" className="mt-4 mb-4">
                        <thead>
                            <tr>
                                <th>用户</th>
                                <th>课程</th>
                                <th>时间</th>
                                <th>经办</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                registrations?.content.map(registration =>
                                    <tr key={id(registration)}>
                                        <td>
                                            <Link to={`/user/${registration.username}`} style={{ textDecoration: "none" }}>
                                                {registration.username}
                                            </Link>
                                        </td>
                                        <td>{registration.course_title} / {registration.course_id}</td>
                                        <td>
                                            {registration.time.substring(0, registration.time.indexOf("T"))}
                                        </td>
                                        <td>{registration.operator}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                style={{ textDecoration: "none" }}
                                                className="pt-0 pb-0 ml-1 mr-1"
                                                onClick={() => dispatch({
                                                    type: RegistrationActionType.SHOW_REMOVER,
                                                    victim: id(registration),
                                                })}
                                            >
                                                移除
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>

                    <Pagination>
                        {
                            pages?.map(value =>
                                <Pagination.Item key={value} active={value === page + 1} onClick={() => navigate(value)}>
                                    {value}
                                </Pagination.Item>
                            )
                        }
                    </Pagination>
                </RegistrationContext.Provider>

                <TutorFooter />
            </Container>
        </>
    );
}

export { RegistrationList };
