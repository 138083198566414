import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Alert, Container, Jumbotron } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { EnrollmentTransfer, LessonTransfer, materialService } from "../service";
import { PassportActionType } from "../store";
import { RecipeTable } from "./recipe";

interface RouteParams {
    id: string,
}

export const CodeReview: FunctionComponent = () => {
    const params = useParams<RouteParams>();
    const history = useHistory();
    const dispatch = useDispatch();
    const [alert, setAlert] = useState<string>();
    const [lesson, setLesson] = useState<LessonTransfer>();
    const [enrollments, setEnrollments] = useState<EnrollmentTransfer[]>();

    const getLesson = useCallback(async () => {
        console.log("Get info for lesson", params.id);

        const response = await materialService().get(`/lesson/${params.id}`);
        switch (response.status) {
            case 200:
                setLesson(response.data as LessonTransfer);
                break;
            case 401:
                console.warn("Invalid or expired token, try to login again");

                dispatch({
                    type: PassportActionType.LOGOUT,
                });

                history.push("/login");
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }
    }, [params.id, dispatch, history])

    const getEnrollments = useCallback(async () => {
        console.log("Get enrollments for lesson", params.id);

        const response = await materialService().get(`/enrollment?lesson=${params.id}`);
        switch (response.status) {
            case 200:
                setEnrollments(response.data as EnrollmentTransfer[]);
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }
    }, [params.id])

    useEffect(() => {
        getLesson();
        getEnrollments();
    }, [getLesson, getEnrollments]);

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>代码评审</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Alert
                    variant={"danger"}
                    show={alert !== undefined}
                    onClose={() => {
                        setAlert(undefined);
                    }}
                    dismissible>
                    {alert}
                </Alert>

                <p>
                    班级编号：{lesson?.circle}
                </p>

                <RecipeTable lesson={lesson} enrollments={enrollments} />

                <TutorFooter />
            </Container>
        </>
    )
}