import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Jumbotron, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { accountService } from "../service";
import { DifficultyLevel, UserSetting } from "./setting";

interface RouteParams {
    username: string
}

export const SettingDetail: FunctionComponent = () => {
    const history = useHistory();
    const params = useParams<RouteParams>();

    const [validated, setValidated] = useState(false);
    const [difficulty, setDifficulty] = useState<string>("normal");
    const [response, setResponse] = useState<number>();

    const difficulties = [
        { name: "简单", level: DifficultyLevel.EASY },
        { name: "普通", level: DifficultyLevel.NORMAL },
        { name: "困难", level: DifficultyLevel.HARD },
    ];

    useEffect(() => {
        const getUser = async () => {
            const response = await accountService().get(`/setting/${params.username}`);

            switch (response.status) {
                case 200:
                    const setting = response.data as UserSetting;
                    setDifficulty(setting.difficulty);
                    break;
                default:
                    console.error("Failed to get user setting", response);
            }
        }
        getUser();

    }, [params.username]);

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            const setting = {
                difficulty: difficulty
            }

            const response = await accountService().put(`/setting/${params.username}`, setting);
            setResponse(response.status);
        }

        setValidated(true);
    };

    const close = () => {
        history.push(`/user`);
    }

    const hint = () => {
        switch (response) {
            case 401:
                return `错误的用户名或者密码！`;
            case 403:
                return `请检查权限是否足够！`;
            default:
                return `错误代码${response}，请联系管理员。`;
        }
    }

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>设定</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Alert
                    variant={response === 200 ? "success" : "danger"}
                    show={typeof (response) === "number"}
                    onClose={() => {
                        setResponse(undefined);
                        setValidated(false)
                    }}
                    dismissible>
                    {
                        response === 200 ? "保存成功。" : hint()
                    }
                </Alert>

                <Form noValidate validated={validated} onSubmit={update}>
                    <Row>
                        <Form.Group as={Col} controlId="username">
                            <Form.Label>账号</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                value={params.username}
                                readOnly={true}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="username">
                            <Form.Label>难度</Form.Label>
                            {
                                difficulties.map((item, index) =>
                                    <Form.Check
                                        key={index}
                                        id={`difficulty-${index}`}
                                        type="radio"
                                        label={item.name}
                                        value={item.level}
                                        checked={item.level === difficulty}
                                        onChange={(e) => setDifficulty(e.currentTarget.value)}
                                    />
                                )
                            }
                        </Form.Group>
                    </Row>

                    <Button variant="primary" type="submit" className="mt-5">
                        保存
                    </Button>

                    <Button variant="link" onClick={close} className="mt-5 ml-2" style={{ textDecoration: "none" }}>
                        返回
                    </Button>
                </Form>

                <TutorFooter />
            </Container>
        </>
    );
}