import React, { ChangeEvent, FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SectionCaption } from "../navigator";
import { CircleTransfer, materialService, TutorTransfer } from "../service";
import { AppStore } from "../store";

interface Params {
    id: string
}

export const CircleBasic: FunctionComponent<Params> = (params) => {
    const passport = useSelector((store: AppStore) => store.passport);
    const history = useHistory();

    const [validated, setValidated] = useState(false);
    const [tutors, setTutors] = useState<TutorTransfer[]>();
    const [alert, setAlert] = useState<string>();
    const [circle, setCircle] = useState<CircleTransfer>({
        id: 0,
        name: "",
        comment: "",
        tutor: "",
        lessons: [],
        users: [],
    });

    const getCircle = useCallback(async () => {
        if (params.id === "0") {
            // create new circle
        } else {
            const response = await materialService().get(`/circle/${params.id}?tutor=${passport.username}`);

            switch (response.status) {
                case 200:
                    setCircle(response.data as CircleTransfer);
                    break;
                default:
                    setAlert(`错误代码${response.status}，请联系管理员。`);
                    break;
            }
        }
    }, [params.id, passport.username]);

    const getTutors = useCallback(async () => {
        const response = await materialService().get(`/tutor`);

        switch (response.status) {
            case 200:
                const result = response.data as TutorTransfer[];
                setTutors(result);
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }
    }, []);

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCircle({
            ...circle,
            [name]: value.trim(),
        });
    };

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            if (params.id === "0") {
                const response = await materialService().post(`/circle`, circle);
                switch (response.status) {
                    case 200:
                        history.goBack();
                        break;
                    default:
                        setAlert(`错误代码${response.status}，请联系管理员。`);
                        break;
                }
            } else {
                const response = await materialService().put(`/circle/${params.id}`, circle);
                switch (response.status) {
                    case 200:
                        setCircle(response.data as CircleTransfer);
                        break;
                    default:
                        setAlert(`错误代码${response.status}，请联系管理员。`);
                        break;
                }
            }
        } else {
            setValidated(true);
        }
    };

    const close = () => {
        history.goBack();
    }

    useEffect(() => {
        getCircle();
        getTutors();
    }, [getCircle, getTutors]);

    return (
        <>
            <SectionCaption title="基础" />

            <Alert
                variant={"danger"}
                show={alert !== undefined}
                onClose={() => {
                    setAlert(undefined);
                }}
                dismissible>
                {alert}
            </Alert>

            <Form noValidate validated={validated} onSubmit={submit}>
                <Row>
                    <Form.Group as={Col} controlId="id">
                        <Form.Label>编号</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly
                            value={circle?.id || ""}
                        />
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} controlId="name">
                        <Form.Label>名字</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="name"
                            pattern=".{2,30}"
                            title="课程名要在2至30个字符之间"
                            value={circle?.name || ""}
                            onChange={change}
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="comment">
                        <Form.Label>注释</Form.Label>
                        <Form.Control
                            type="text"
                            name="comment"
                            value={circle?.comment || ""}
                            onChange={change}
                        />
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} controlId="tutor">
                        <Form.Label>导师</Form.Label>
                        <Form.Control
                            required
                            as="select"
                            name="tutor"
                            title="请选择导师"
                            value={circle?.tutor}
                            onChange={change}
                        >
                            <option label=""></option>
                            {
                                tutors?.map(tutor =>
                                    <option value={tutor.id} key={tutor.id}>
                                        {tutor.name}
                                    </option>
                                )
                            }
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="thumbnail">
                        <Form.Label>图示</Form.Label>
                        <Form.Control
                            required
                            as="select"
                            name="thumbnail"
                            value={circle?.thumbnail}
                            onChange={change}
                        >
                            <option label=""></option>
                            <option value="https://resource.codemage.cn/poster-computational-thinking.svg" key={1}>
                                计算思维入门篇
                            </option>
                            <option value="https://resource.codemage.cn/poster-math.svg" key={2}>
                                计算思维数学篇
                            </option>
                            <option value="https://resource.codemage.cn/poster-usaco.svg" key={3}>
                                美国信奥青铜级
                            </option>
                        </Form.Control>
                    </Form.Group>
                </Row>

                <Button variant="primary" type="submit" className="mt-4">
                    保存
                </Button>

                <Button variant="link" onClick={close} className="mt-4 ml-2" style={{ textDecoration: "none" }}>
                    返回
                </Button>
            </Form>
        </>
    );
}