import React, { FunctionComponent, useMemo, useState } from "react";
import { Button, Container, Jumbotron, Table } from "react-bootstrap";
import { readString } from 'react-papaparse';
import { Column, useTable } from "react-table";
import { accountService, UserTransfer } from "../service";

const UserBatch: FunctionComponent = () => {
    const [users, setUsers] = useState<UserTransfer[]>([]);

    const columns: Column<UserTransfer>[] = useMemo(
        () => [
            {
                Header: "用户名",
                accessor: "username",
            },
            {
                Header: "密码",
                accessor: "password",
            },
            {
                Header: "全名",
                accessor: "fullname",
            },
            {
                Header: "监护人",
                accessor: "guardian",
            },
            {
                Header: "渠道",
                accessor: "channel",
            },
            {
                Header: "到期",
                accessor: "dateExpired",
            },
        ], [])

    const table = useTable({ columns, data: users })

    const clipboard = async () => {
        const text = await navigator.clipboard.readText();
        readString(text, {
            worker: true,
            header: true,
            skipEmptyLines: false,
            complete: (results) => {
                const entities = results.data.map(item => {
                    return {
                        username: (item as any)["username"],
                        password: (item as any)["password"],
                        fullname: (item as any)["fullname"],
                        guardian: (item as any)["guardian"],
                        channel: (item as any)["channel"],
                        dateExpired: (item as any)["expired"],
                        roles: [],
                    };
                }).filter(item => {
                    return item.username && item.password
                });

                setUsers(entities);
            }
        });
    };

    const update = async () => {
        const response = await accountService().post(`/users`, users);
        switch (response.status) {
            case 200:
                break;
            default:

                break;
        }

    }

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>导入</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Button variant="primary" onClick={() => clipboard()}>
                    粘贴
                </Button>

                <Button variant="primary" onClick={() => update()} disabled={users.length === 0}>
                    提交
                </Button>

                <Table striped bordered hover className="mt-4 mb-4" size="sm" {...table.getTableProps()}>
                    <thead>
                        {
                            table.headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps()}>
                                                {
                                                    column.render('Header')
                                                }
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))}
                    </thead>
                    <tbody {...table.getTableBodyProps()}>
                        {
                            table.rows.map(row => {
                                table.prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map(cell => {
                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        {
                                                            cell.render('Cell')
                                                        }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            </Container>
        </>
    );
}

export { UserBatch };
