import React, { FunctionComponent } from "react";
import { Container, Jumbotron, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { UserBasic } from "./userbasic";


interface RouteParams {
    username: string
}

export const UserDetail: FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>用户</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <UserBasic
                            username={params.username}
                        />
                    </Tab>
                </Tabs>

                <TutorFooter />
            </Container>
        </>
    );
}