import axios, { CancelTokenSource } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { BasicEdit } from "./basicedit";
import { RoleEdit } from "./roleedit";
import { UserRole } from "./userrole";

interface Params {
    username: string,
}

let source: CancelTokenSource;

/**
 * Panel to display basic information of the user.
 * 
 * Admin can modify the basic info here.
 */
export const UserBasic: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [user, setUser] = useState<UserTransfer>();
    const [basic, setBasic] = useState<boolean>(false);
    const [role, setRole] = useState<boolean>(false);

    const getUser = useCallback(async () => {
        const source = axios.CancelToken.source();
        const response = await accountService().get(`/user/${params.username}`, {
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                setUser(response.data as UserTransfer);

                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `意外错误：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [params.username, dispatch])

    useEffect(() => {
        getUser();

        return () => {
            source?.cancel();
        }
    }, [getUser]);

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <Table borderless hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>个人信息</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right", cursor: "pointer" }} onClick={() => setBasic(true)} >
                                    <span role="img" aria-label="edit">✏️</span>
                                    {
                                        user &&
                                        <BasicEdit
                                            show={basic}
                                            user={user}
                                            onExit={() => { setBasic(false); getUser(); }}
                                        />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>编号</td>
                                <td>
                                    {user?.id}
                                </td>
                                <td>用户的内部编号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>代号</td>
                                <td>
                                    {user?.username}
                                </td>
                                <td>用户的独特代号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>全名</td>
                                <td>
                                    {user?.fullname}
                                </td>
                                <td>用户的完整称谓</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>家长</td>
                                <td>
                                    {user?.guardian}
                                </td>
                                <td>家庭联系人</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>手机</td>
                                <td>
                                    {user?.mobile}
                                </td>
                                <td>移动电话号码</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>邮箱</td>
                                <td>
                                    {user?.email}
                                </td>
                                <td>电子邮箱地址</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>渠道</td>
                                <td>
                                    {user?.channel}
                                </td>
                                <td>创建用户的渠道代码</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>创建</td>
                                <td>
                                    {user?.dateCreated}
                                </td>
                                <td>创建账户的日期</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>到期</td>
                                <td>
                                    {user?.dateExpired}
                                    {new Date(user?.dateExpired || "2020-01-01") > new Date() ? " ✔️ " : " ❌ "}
                                </td>
                                <td>到期后不能执行代码</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Card className="mb-4">
                <Card.Body>
                    <Table borderless hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>使用权限</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right", cursor: "pointer" }} onClick={() => setRole(true)}>
                                    <span role="img" aria-label="edit">✏️</span>
                                    {
                                        user &&
                                        <RoleEdit
                                            user={user}
                                            show={role}
                                            onExit={() => { setRole(false); getUser(); }}
                                        />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>状态</td>
                                <td>
                                    {user?.active === false ? "停用" : "正常"}
                                    {user?.active === false ? " ❌ " : " ✔️ "}
                                </td>
                                <td>账号是否可以使用</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>角色</td>
                                <td>
                                    <UserRole roles={user?.roles} />
                                </td>
                                <td>授予用户的角色</td>
                                <td>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}