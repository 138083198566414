import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    user: UserTransfer,
    show: boolean,
    onExit: () => void,
}

export const BasicEdit: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState<UserTransfer>(params.user);
    const [spin, setSpin] = useState(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(params.show);
        setUser(params.user);
    }, [params.show, params.user]);

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setUser({
            ...user,
            [name]: value.trim(),
        });
    };

    const update = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = user.id === 0 ? await accountService().post(`/user`, user) : await accountService().put(`/user`, user);

            setSpin(false);
            setShow(false);

            switch (response.status) {
                case 200:
                    break;
                case 409:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "😞",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `该移动电话号码已经存在，请联系管理员。`,
                    });
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `意外错误：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }
        } else {
            setValidated(true);
        }
    };

    return (
        <>
            <Modal show={show} size="lg" onExit={() => params.onExit()}>
                <Modal.Header>
                    <Modal.Title>编辑</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={update} id="form" >
                        <Row>
                            <Form.Group as={Col} controlId="id">
                                <Form.Label>编号</Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={user.id}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="channel">
                                <Form.Label>渠道</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="channel"
                                    readOnly={user.id !== 0}
                                    value={user.channel || ""}
                                    onChange={change}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="username">
                                <Form.Label>账号</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={user.username}
                                    required
                                    readOnly={user.id !== 0}
                                    pattern="[a-zA-Z0-9_]+"
                                    minLength={4}
                                    onChange={change}
                                />
                                <Form.Control.Feedback type="invalid">
                                    账号需要6个以上的英文大小写字母或者数字
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="password">
                                <Form.Label>密码</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="密码"
                                    name="password"
                                    pattern="(?=.*[a-zA-Z0-9]).{8,}"
                                    onChange={change}
                                />
                                <Form.Control.Feedback type="invalid">
                                    密码需要包含8个以上的大小写字母以及数字
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="fullname">
                                <Form.Label>全名</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fullname"
                                    value={user.fullname || ""}
                                    onChange={change}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="guardian">
                                <Form.Label>家长</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="guardian"
                                    value={user.guardian || ""}
                                    onChange={change}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="mobile">
                                <Form.Label>手机</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="mobile"
                                    pattern="^1[0-9]{10}$"
                                    value={user.mobile || ""}
                                    onChange={change}
                                />
                                <Form.Control.Feedback type="invalid">
                                    请确保手机格式正确
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} controlId="email">
                                <Form.Label>邮箱</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={user.email || ""}
                                    onChange={change}
                                />
                                <Form.Control.Feedback type="invalid">
                                    请确保电子邮件格式正确
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="date-created">
                                <Form.Label>创建</Form.Label>
                                <Form.Control
                                    type="date"
                                    readOnly
                                    value={user.dateCreated || ""}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="date-expired">
                                <Form.Label>到期</Form.Label>
                                <Form.Control
                                    type="date"
                                    required
                                    name="dateExpired"
                                    value={user.dateExpired || ""}
                                    onChange={change}
                                />
                            </Form.Group>
                        </Row>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" form="form" disabled={spin}>
                        {
                            spin && <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                        }
                        确认
                    </Button>

                    <Button variant="outline-secondary" type="cancel" onClick={() => setShow(false)}>
                        取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}