import moment from "moment-timezone";
import React, { FunctionComponent } from "react";
import { Badge, Col, Media, Row } from "react-bootstrap";
import { AiOutlineUser } from "react-icons/ai";
import { ExecuteReply, Message, MessageStatus } from "../message";
import { SubmissionTransfer } from "../service";

interface SubmissionMetaParams {
    submission: SubmissionTransfer;
}

interface ReplyStatusParams {
    message: Message;
}

const ReplyStatus: FunctionComponent<ReplyStatusParams> = (params) => {
    const content = params.message?.content as ExecuteReply;

    switch (content?.status) {
        case MessageStatus.ok:
            return <Badge variant="success" className="ml-2">成功</Badge>;
        case MessageStatus.error:
            return <Badge variant="danger" className="ml-2">出错</Badge>;
        case MessageStatus.aborted:
            return <Badge variant="info" className="ml-2">终止</Badge>;
        default:
            return <Badge variant="warning" className="ml-2">异常</Badge>;
    }
}

export const SubmissionMeta: FunctionComponent<SubmissionMetaParams> = (params) => {
    const message = params.submission.execute_input;
    const date = message?.header.date;
    const time = date ? moment(date.substring(0, date.indexOf("."))).format("YYYY-MM-DD HH:mm:ss") : "丢失";

    return (
        <Media className="mt-5 mb-3 p-3" style={{ backgroundColor: "#f8f9fa" }}>
            <AiOutlineUser size={48} style={{ color: "grey" }} className="mr-4" />
            <Media.Body>
                <Row>
                    <Col>用户：{params.submission.username}</Col>
                    <Col>
                        时间：{time}
                        <ReplyStatus message={params.submission.execute_reply} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        操作：{params.submission.id}
                    </Col>
                    <Col>片段：{params.submission.snippet}</Col>
                </Row>
            </Media.Body>
        </Media>
    );
}
