import React, { FunctionComponent } from "react";
import { Badge } from "react-bootstrap";

interface Params {
    roles?: string[],
}

export const UserRole: FunctionComponent<Params> = (params) => {
    const badge = (role: string) => {
        switch (role) {
            case "student":
                return "学生";
            case "tutor":
                return "导师";
            case "principal":
                return "校长";
            case "admin":
                return "管理员";
        }
    }

    const color = (role: string) => {
        switch (role) {
            case "student":
                return "primary";
            case "tutor":
                return "success";
            case "principal":
                return "warning";
            case "admin":
                return "danger";
        }
    }

    return (
        <>
            {
                params.roles?.map(role =>
                    <Badge key={role} variant={color(role)} className="mr-1">
                        {badge(role)}
                    </Badge>
                )
            }
        </>
    );
}