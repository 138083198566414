import { PassportAction, PassportActionType, PassportStore } from "./passportactions";
import jwt_decode from "jwt-decode";

const initial: PassportStore = {
    authenticated: false,
    username: "游客",
    roles: [],
    token: undefined,
};

interface TokenFormat {
    exp: number;
    iat: number;
    roles: string;
    sub: string;
}

export function passportReducer(state = initial, action: PassportAction): PassportStore {
    switch (action.type) {
        case PassportActionType.LOGIN:
            localStorage.setItem("access-token", action.token!);
            localStorage.setItem("username", action.username);

            return {
                ...state,
                authenticated: true,
                username: action.username,
                roles: jwt_decode<TokenFormat>(action.token || "").roles.split(","),
                token: action.token,
            };
        case PassportActionType.LOGOUT:
            localStorage.removeItem("access-token");
            localStorage.removeItem("username");

            return {
                ...state,
                authenticated: false,
                username: "游客",
                roles: [],
                token: undefined,
            };
        default:
            return state;
    }
}
