import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Alert, Button, FormControl, Modal, Table } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Link } from "react-router-dom";
import { SectionCaption } from "../navigator";
import { EnrollmentTransfer, materialService, accountService, UserTransfer } from "../service";

interface Params {
    id: string;
}

export const CircleEnrollment: FunctionComponent<Params> = (props) => {
    const [enrollments, setEnrollments] = useState<EnrollmentTransfer[]>();
    const [userVisible, setUserVisible] = useState(false);
    const [graduate, setGraduate] = useState<string>(); // the account to graduate
    const [dismissal, setDismissal] = useState<string>(); // the account to remove
    const [users, setUsers] = useState<UserTransfer[]>();
    const [alias, setAlias] = useState<string>();
    const [alert, setAlert] = useState<string>();

    const get = useCallback(async () => {
        console.log("Get enrollments for learning circle", props.id);

        const response = await materialService().get(`/enrollment?circle=${props.id}`);
        switch (response.status) {
            case 200:
                setEnrollments(response.data as EnrollmentTransfer[]);
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }

    }, [props.id])

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        setAlias(event.target.value);
    };

    const add = async () => {
        console.log("Enroll user", alias, "into learning circle", props.id);

        const response = await materialService().post(`/enrollment?circle=${props.id}&user=${alias}`);
        switch (response.status) {
            case 200:
                await get();
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }

        setUserVisible(false);
    }

    const remove = async (username: string) => {
        console.log("Remove user", username, "from the learning circle", props.id);

        const user = await materialService().delete(`/enrollment?circle=${props.id}&user=${username}`);
        switch (user.status) {
            case 200:
                await get();
                break;
            default:
                setAlert(`错误代码${user.status}，请联系管理员。`);
                break;
        }
    }

    const certify = async () => {
        console.log("Certify the user with alias", alias);

        const user = await materialService().put(`/enrollment?circle=${props.id}&user=${graduate}&alias=${alias}`);
        switch (user.status) {
            case 200:
                await get();
                break;
            default:
                setAlert(`错误代码${user.status}，请联系管理员。`);
                break;
        }

        setGraduate(undefined);
    }

    const user = useCallback(async () => {
        console.log("Get all usernames");

        const response = await accountService().get(`/user?active=true`);
        switch (response.status) {
            case 200:
                setUsers(response.data as UserTransfer[]);
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }
    }, [])

    const fullname = (username: string) => {
        const result = users?.find(user => {
            return user.username === username;
        });
        return result?.fullname || "";
    }

    useEffect(() => {
        // load data in sequence
        const load = async () => {
            await user();
            await get();
        }
        load();
    }, [get, user]);

    return (
        <>
            <SectionCaption title="学员" />

            <Alert
                variant={"danger"}
                show={alert !== undefined}
                onClose={() => {
                    setAlert(undefined);
                }}
                dismissible>
                {alert}
            </Alert>

            <Modal show={userVisible} onHide={() => setUserVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>添加</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Typeahead
                        id="usernames-typeahead"
                        labelKey={(user) => `${user.username} / ${user.fullname}`}
                        onChange={selected => setAlias(selected.length === 0 ? undefined : selected[0].username)}
                        options={users || []}
                        selected={users?.filter(user => user.username === alias) || []}
                        placeholder="用户名"
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={add}>
                        确定
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={graduate !== undefined} onHide={() => setGraduate(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>名字</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormControl
                        placeholder="用户名"
                        onChange={change}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={certify}>
                        确定
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button variant="primary" onClick={() => setUserVisible(true)}>
                添加
            </Button>

            <Modal show={dismissal !== undefined} onHide={() => setDismissal(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>提示</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    即将把用户{dismissal}从本课程删除。你确认吗？
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            remove(`${dismissal}`);
                            setDismissal(undefined);
                        }}
                    >
                        确定
                    </Button>
                </Modal.Footer>
            </Modal>

            <Table bordered hover size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>账号</th>
                        <th>姓名</th>
                        <th>昵称</th>
                        <th>证书</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        enrollments?.map(enrollment =>
                            <tr key={enrollment.user}>
                                <td>
                                    <Link to={`/user/${enrollment.user}`} style={{ textDecoration: "none" }} >
                                        {enrollment.user}
                                    </Link>
                                </td>
                                <td>
                                    {`${fullname(enrollment.user)}`}
                                </td>
                                <td>
                                    {enrollment.alias}
                                </td>
                                <td>
                                    {`https://learn.codemage.cn/certificate/${enrollment.certificate}`}
                                </td>
                                <td>
                                    <Button
                                        size="sm"
                                        style={{ textDecoration: "none" }}
                                        className="pt-0 pb-0 ml-1 mr-1"
                                        onClick={() => setDismissal(enrollment.user)}
                                    >
                                        移除
                                    </Button>
                                    <Button
                                        size="sm"
                                        style={{ textDecoration: "none" }}
                                        className="pt-0 pb-0 ml-1 mr-1"
                                        onClick={() => setGraduate(enrollment.user)}
                                    >
                                        结业
                                    </Button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
}