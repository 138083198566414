import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, CodeTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    code: CodeTransfer,
    show: boolean,
    onExit: () => void,
}

export const CodeEdit: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [code, setCode] = useState<Partial<CodeTransfer>>();
    const [show, setShow] = useState<boolean>(params.show);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
        setCode(params.code);
        setValidated(false);
    };

    const finalize = () => {
        params.onExit();
    }

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCode({
            ...code,
            [name]: value.trim(),
        });
    };

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            setSpin(true);

            const response = await accountService().put(`/code`, code);

            switch (response.status) {
                case 200:
                    break;
                case 409:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "😞",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `机构代码已存在或者校长已经拥有机构了。`,
                    });
                    break;
                default:
                    dispatch<SystemNotifyAction>({
                        type: SystemActionType.NOTIFY,
                        toast: true,
                        title: "🛸",
                        subtitle: new Date().toLocaleTimeString(),
                        content: `意外错误：${response.status} ${response.statusText}，请联系管理员。`,
                    });
                    break;
            }

            setShow(false);
            setSpin(false);
        }
        else {
            setValidated(true);
        }
    };

    return (
        <Modal show={show} onEnter={initialize} onExited={finalize}>
            <Modal.Header>
                <Modal.Title>
                    邀请
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={submit} id="form">
                    <Row>
                        <Form.Group as={Col} controlId="id">
                            <Form.Label>编号</Form.Label>
                            <Form.Control
                                type="text"
                                readOnly
                                value={code?.id || ""}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="code">
                            <Form.Label>编码</Form.Label>
                            <Form.Control
                                type="text"
                                name="fullname"
                                value={code?.code || ""}
                                onChange={change}
                                readOnly
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="count">
                            <Form.Label>次数</Form.Label>
                            <Form.Control
                                as="select"
                                name="count"
                                value={code?.count || 0}
                                onChange={change}
                            >
                                {
                                    Array.from({ length: 31 }, (value, key) => key).map(i =>
                                        <option key={i} value={i}>{i}</option>
                                    )
                                }
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="creator">
                            <Form.Label>发起人</Form.Label>
                            <Form.Control
                                type="text"
                                name="creator"
                                value={code?.creator || ""}
                                onChange={change}
                                readOnly
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="expiration">
                            <Form.Label>时限</Form.Label>
                            <Form.Control
                                type="date"
                                required
                                name="expiration"
                                value={code?.expiration || "2020-01-01"}
                                onChange={change}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="creator">
                            <Form.Label>注释</Form.Label>
                            <Form.Control
                                type="text"
                                name="comment"
                                value={code?.comment || ""}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form">
                    {
                        spin &&
                        <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                    }
                    确定
                </Button>

                <Button variant="outline-secondary" type="cancel" onClick={() => setShow(false)}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    );
}