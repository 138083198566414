import moment from "moment-timezone";
import React, { ChangeEvent, FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Jumbotron, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TutorFooter } from "../navigator";
import { ChapterTransfer, LessonTransfer, materialService } from "../service";

interface RouteParams {
    id: string;
}

export const LessonDetail: FunctionComponent = () => {
    const params = useParams<RouteParams>();
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState<string>();
    const [chapters, setChapters] = useState<ChapterTransfer[]>();
    const [lesson, setLesson] = useState<LessonTransfer>({
        id: 0,
        name: "",
        time: moment().format("YYYY-MM-DDTHH:mm"),
        zone: "Asia/Shanghai",
        duration: 60,
        webinar: "",
        record: "",
        circle: query.get("circle") || undefined,
        summary: "",
        tutor: 0,
        chapter: {
            id: 0,
            title: "",
            summary: "",
            sections: [],
        },
        prerequisites: [],
        relatives: [],
    });

    const getLesson = useCallback(async () => {
        if (params.id !== "0") {
            const response = await materialService().get(`/lesson/${params.id}`);

            switch (response.status) {
                case 200:
                    setLesson(response.data as LessonTransfer);
                    break;
                default:
                    setAlert(`错误代码${response.status}，请联系管理员。`);
                    break;
            }
        }
    }, [params.id])

    const getChapters = useCallback(async () => {
        const response = await materialService().get(`/chapter`);

        switch (response.status) {
            case 200:
                setChapters(response.data as ChapterTransfer[]);
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }
    }, [])

    const change = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setLesson({
            ...lesson,
            [name]: value.trim(),
        });
    };

    const choose = (selected: ChapterTransfer[]) => {
        setLesson({
            ...lesson,
            chapter: selected.length === 0 ? undefined : {
                id: selected[0].id, title: "",
                summary: "",
                sections: []
            },
        });
    }

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.currentTarget.checkValidity()) {
            const response = await materialService().post(`/lesson`, lesson);
            switch (response.status) {
                case 200:
                    history.goBack();
                    break;
                default:
                    setAlert(`错误代码${response.status}，请联系管理员。`);
                    break;
            }
        }

        setValidated(true);
    };

    useEffect(() => {
        getLesson();
        getChapters();
    }, [getLesson, getChapters]);

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>课程详情</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Alert
                    variant={"danger"}
                    show={alert !== undefined}
                    onClose={() => {
                        setAlert(undefined);
                    }}
                    dismissible>
                    {alert}
                </Alert>

                <Form noValidate validated={validated} onSubmit={submit}>
                    <Row>
                        <Form.Group as={Col} controlId="id">
                            <Form.Label>编号</Form.Label>
                            <Form.Control
                                type="text"
                                name="id"
                                readOnly
                                value={params?.id}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="circle">
                            <Form.Label>班级</Form.Label>
                            <Form.Control
                                type="text"
                                name="circle"
                                readOnly
                                value={lesson.circle || ""}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="chapter">
                            <Form.Label>章节</Form.Label>
                            <Typeahead
                                id="circle-chapter-typeahead"
                                labelKey={(chapter) => `${chapter.course?.title} / ${chapter.title}`}
                                onChange={selected => choose(selected)}
                                options={chapters || []}
                                inputProps={{ required: true }}
                                selected={chapters?.filter(chapter => chapter.id === lesson.chapter?.id) || []}
                                placeholder="选择要讲授的章节"
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="datetime">
                            <Form.Label>时间</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="time"
                                value={lesson.time}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="webinar">
                            <Form.Label>网课</Form.Label>
                            <Form.Control
                                type="text"
                                name="webinar"
                                value={lesson.webinar || ""}
                                onChange={change}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="duration">
                            <Form.Label>时长</Form.Label>
                            <Form.Control
                                required
                                as="select"
                                name="duration"
                                title="请选择时长"
                                value={lesson?.duration}
                                onChange={change}
                            >
                                <option label=""></option>
                                <option value={60} key={60}>60分钟</option>
                                <option value={90} key={90}>90分钟</option>
                            </Form.Control>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="record">
                            <Form.Label>录像</Form.Label>
                            <Form.Control
                                type="text"
                                name="record"
                                value={lesson.record || ""}
                                onChange={change}
                            />
                        </Form.Group>
                    </Row>

                    <Button variant="primary" type="submit" className="mt-3">
                        保存
                    </Button>
                </Form>

                <TutorFooter />
            </Container>
        </>
    )
}