import React, { FunctionComponent } from "react";
import { Switch } from "react-router-dom";
import { CircleDetail } from "../circle";
import { CodeDetail, CodeList } from "../code";
import { DashboardComponent } from "../dashboard";
import { LessonDetail } from "../lesson";
import { LoginComponent } from "../passport";
import { RegistrationList } from "../registration";
import { CodeReview } from "../review";
import { SettingDetail } from "../setting";
import { TenantDetail, TenantList } from "../tenant";
import { UserBatch, UserDetail, UserList } from "../user";
import { PrivateRoute, PublicRoute } from "./statehouse";


export const ContentRouter: FunctionComponent = () => {
    return (
        <>
            <Switch>
                <PublicRoute path="/login" component={LoginComponent} exact restricted />
                <PrivateRoute path="/" component={DashboardComponent} exact />
                <PrivateRoute path="/dashboard" component={DashboardComponent} exact />
                <PrivateRoute path="/tenant" component={TenantList} exact />
                <PrivateRoute path="/tenant/:code" component={TenantDetail} exact />
                <PrivateRoute path="/tenant/:code/circle/:id" component={CircleDetail} exact />
                <PrivateRoute path="/lesson/:id" component={LessonDetail} exact />
                <PrivateRoute path="/review/:id" component={CodeReview} exact />
                <PrivateRoute path="/user" component={UserList} exact />
                <PrivateRoute path="/user/:username" component={UserDetail} exact />
                <PrivateRoute path="/users" component={UserBatch} exact />
                <PrivateRoute path="/setting/:username" component={SettingDetail} exact />
                <PrivateRoute path="/code" component={CodeList} exact />
                <PrivateRoute path="/code/:id" component={CodeDetail} exact />
                <PrivateRoute path="/registration" component={RegistrationList} exact />
            </Switch>
        </>
    );
}