import React, { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, TenantTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    show: boolean;
    tenant?: string;
    fullname?: string;
    onExit: () => void;
}

export const TenantName: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [fullname, setFullname] = useState<string>();
    const [show, setShow] = useState<boolean>(false);
    const [spin, setSpin] = useState(false);

    useEffect(() => {
        setShow(params.show);
    }, [params.show]);

    const initialize = () => {
        setFullname(params.fullname);
    };

    const finalize = () => {
        params.onExit();
    }

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setSpin(true);

        const transfer: Partial<TenantTransfer> = { fullname: fullname };
        const response = await accountService().put(`/tenant/${params.tenant}`, transfer);

        setSpin(false);

        switch (response.status) {
            case 200:
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }

        setShow(false);
    }

    return (
        <>
            <Modal show={show} onEnter={initialize} onExited={finalize}>
                <Modal.Header>
                    <Modal.Title>
                        名称
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form noValidate onSubmit={submit} id="form">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Control
                                    type="text"
                                    value={fullname || ""}
                                    onChange={(event) => setFullname(event.currentTarget.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" form="form" disabled={spin}>
                        {
                            spin && <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                        }
                        确认
                    </Button>

                    <Button variant="outline-secondary" type="cancel" onClick={() => setShow(false)}>
                        取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}