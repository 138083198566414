import moment from "moment-timezone";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Alert, Button, Modal, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { SectionCaption } from "../navigator";
import { LessonTransfer, materialService } from "../service";

interface Params {
    id: string;
}

export const LessonList: FunctionComponent<Params> = (props) => {
    const history = useHistory();

    const [lessons, setLessons] = useState<LessonTransfer[]>();
    const [candidate, setCandidate] = useState<number>();
    const [alert, setAlert] = useState<string>();

    const get = useCallback(async () => {
        const response = await materialService().get(`/lesson?circle=${props.id}`);

        switch (response.status) {
            case 200:
                setLessons(response.data as LessonTransfer[]);
                break;
            default:
                setAlert(`错误代码${response}，请联系管理员。`);
                break;
        }
    }, [props.id])

    const remove = async (id: string) => {
        const response = await materialService().delete(`/lesson/${id}`);
        switch (response.status) {
            case 200:
                break;
            default:
                setAlert(`错误代码${response.status}，请联系管理员。`);
                break;
        }

        // update the lesson list
        get();
    }

    const review = (id: number) => {
        const url = `/review/${id}`;

        history.push(url);
    }

    const visit = (id: number) => {
        const url = `https://learn.codemage.cn/lesson/${id}`;

        const win = window.open(url, "_blank");
        win?.focus();
    }

    useEffect(() => {
        get();

    }, [get]);

    return (
        <>
            <SectionCaption title="课程" />

            <Alert
                variant={"danger"}
                show={alert !== undefined}
                onClose={() => {
                    setAlert(undefined);
                }}
                dismissible>
                {alert}
            </Alert>

            <Modal show={candidate !== undefined} onHide={() => setCandidate(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>提示</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    即将删除课程{candidate}。你确认吗？
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            remove(`${candidate}`);
                            setCandidate(undefined);
                        }}
                    >
                        确定
                    </Button>
                </Modal.Footer>
            </Modal>

            <LinkContainer to={`/lesson/0?circle=${props.id}`}>
                <Button variant="primary">
                    添加
                </Button>
            </LinkContainer>

            <Table bordered hover size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>编号</th>
                        <th>课程</th>
                        <th>时间</th>
                        <th>时长</th>
                        <th>会议</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lessons?.map(lesson =>
                            <tr key={lesson.id}>
                                <td >
                                    {lesson.id}
                                </td>
                                <td>
                                    <Link to={`/lesson/${lesson.id}`} style={{ textDecoration: "none" }} >
                                        {lesson.name} ({lesson.chapter?.id})
                                    </Link>
                                </td>
                                <td>
                                    {moment(lesson.time).format("YYYY-MM-DD HH:mm")}
                                </td>
                                <td>
                                    {lesson.duration}
                                </td>
                                <td>
                                    <a href={lesson.webinar}>网课链接</a>
                                </td>
                                <td>
                                    <Button
                                        size="sm"
                                        style={{ textDecoration: "none" }}
                                        className="pt-0 pb-0 ml-1 mr-1"
                                        onClick={() => review(lesson.id)}
                                    >
                                        评审
                                    </Button>

                                    <Button
                                        size="sm"
                                        style={{ textDecoration: "none" }}
                                        className="pt-0 pb-0 ml-1 mr-1"
                                        onClick={() => visit(lesson.id)}
                                    >
                                        访问
                                    </Button>

                                    <Button
                                        size="sm"
                                        style={{ textDecoration: "none" }}
                                        className="pt-0 pb-0 ml-1 mr-1"
                                        onClick={() => setCandidate(lesson.id)}
                                    >
                                        移除
                                    </Button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
}