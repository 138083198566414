import axios, { CancelTokenSource } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, CodeTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { CodeEdit } from "./codeedit";

interface Params {
    id: string,
}

let source: CancelTokenSource;

export const CodeBasic: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [code, setCode] = useState<CodeTransfer>();
    const [edit, setEdit] = useState<boolean>(false);

    const getCode = useCallback(async () => {
        const source = axios.CancelToken.source();
        const response = await accountService().get(`/code/${params.id}`, {
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                setCode(response.data as CodeTransfer);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [params.id, dispatch])

    useEffect(() => {
        getCode();

        return () => {
            source?.cancel();
        }
    }, [getCode]);

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <Table borderless hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>邀请信息</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right", cursor: "pointer" }} onClick={() => setEdit(true)}>
                                    <span role="img" aria-label="edit">✏️</span>
                                    {
                                        code &&
                                        <CodeEdit
                                            code={code}
                                            show={edit}
                                            onExit={() => { setEdit(false); getCode(); }}
                                        />
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>编号</td>
                                <td>
                                    {code?.id}
                                </td>
                                <td>机构的内部编号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>代码</td>
                                <td>
                                    {code?.code}
                                </td>
                                <td>用来注册的邀请码</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>次数</td>
                                <td>
                                    {code?.count}
                                </td>
                                <td>可以用来注册的次数</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>时限</td>
                                <td>
                                    {code?.expiration}
                                </td>
                                <td>邀请码的有效期</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>创建</td>
                                <td>
                                    {code?.creator}
                                </td>
                                <td>邀请码的负责人</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>注释</td>
                                <td>
                                    {code?.comment}
                                </td>
                                <td>备注信息</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}