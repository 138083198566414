import React, { FunctionComponent } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { BsGrid3X3Gap } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { UserComponent } from "./user";

export const Navigator: FunctionComponent = () => {
    const home = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        window.open("https://www.codemage.cn");
    }

    return (
        <Navbar bg="primary" variant="dark" expand="lg">
            <Container>
                <LinkContainer to="https://www.codemage.cn" onClick={home} style={{ cursor: "pointer" }}>
                    <BsGrid3X3Gap size={22} color="white" className="mr-2 mt-1" />
                </LinkContainer>

                <LinkContainer to="/">
                    <Navbar.Brand>
                        管理
                    </Navbar.Brand>
                </LinkContainer>

                <Navbar.Toggle aria-controls="mainframe" />

                <Navbar.Collapse id="mainframe">
                    <Nav className="mr-auto">
                        <NavDropdown title="机构" id="dropdown-tenant">
                            <LinkContainer to="/tenant">
                                <NavDropdown.Item>
                                    浏览
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <NavDropdown title="用户" id="dropdown-user">
                            <LinkContainer to="/user">
                                <NavDropdown.Item>
                                    浏览
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/code">
                                <NavDropdown.Item>
                                    邀请
                                </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/users">
                                <NavDropdown.Item>
                                    导入
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        <NavDropdown title="授权" id="dropdown-registration">
                            <LinkContainer to="/registration">
                                <NavDropdown.Item>
                                    课程
                                </NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <UserComponent />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}