import axios, { CancelTokenSource } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, CircleTransfer, materialService, UserTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";

interface Params {
    tenant: string,
}

let source: CancelTokenSource;

export const CircleList: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [circles, setCircles] = useState<CircleTransfer[]>();
    const [tutors, setTutors] = useState<UserTransfer[]>();

    const getTutors = useCallback(async () => {
        source = axios.CancelToken.source();

        const response = await accountService().get(`/tenant/${params.tenant}/user`, {
            cancelToken: source.token,
            params: {
                role: "tutor",
            }
        });

        switch (response.status) {
            case 200:
                setTutors(response.data as UserTransfer[]);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [dispatch, params.tenant]);

    const getCircles = useCallback(async () => {
        source = axios.CancelToken.source();

        const response = await materialService().get(`/tenant/${params.tenant}/circle`, {
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                // collect users information
                const info = response.data as CircleTransfer[];
                setCircles(info);

                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [params.tenant, dispatch]);

    useEffect(() => {
        getTutors();
        getCircles();

        return () => {
            source.cancel();
        }
    }, [getTutors, getCircles]);

    return (
        <>
            <Table bordered hover size="sm" className="mt-4 mb-4">
                <thead>
                    <tr>
                        <th>编号</th>
                        <th>名称</th>
                        <th>注释</th>
                        <th>导师</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        circles?.map(circle =>
                            <tr key={circle.id}>
                                <td>
                                    {circle.id}
                                </td>
                                <td>
                                    {/* <Link to={`/tenant/${params.tenant}/circle/${circle.id}`} style={{ textDecoration: "none" }}> */}
                                    {circle.name}
                                    {/* </Link> */}
                                </td>
                                <td>
                                    {circle.comment}
                                </td>
                                <td>
                                    {tutors?.find(tutor => tutor.username === circle.tutor)?.fullname}
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    );
}