import React, { FunctionComponent } from "react";
import { Container, Jumbotron } from "react-bootstrap";
import { ServiceStatusComponent } from "./servicestatus";

export const DashboardComponent: FunctionComponent = () => {

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>仪表盘</h1>
                </Container>
            </Jumbotron>

            <ServiceStatusComponent />
        </>
    )

}