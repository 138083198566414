import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from '@nivo/pie';
import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import { ExecuteReply, MessageStatus } from "../message";
import { SubmissionTransfer } from "../service";

interface GradeParams {
    submissions: SubmissionTransfer[];
}

interface ErrorEntry {
    id: string;
    label: string;
    value: number;
}

interface ExecuteEntry extends Record<string, any> {
    snippet: string;
    ok: number;
    error: number;
}

export const Dashboard: FunctionComponent<GradeParams> = (params) => {

    const count = (status: MessageStatus): Record<string, number> => {
        const subs = params.submissions.filter(submission => {
            const reply = submission.execute_reply.content as ExecuteReply;
            return reply.status === status;
        });

        const stats = subs.reduce((result, submission) => {
            result[submission.snippet] = result[submission.snippet] ? result[submission.snippet] + 1 : 1;
            return result;
        }, {} as Record<string, number>);

        return stats;
    }

    const executes = (): ExecuteEntry[] => {
        console.log("Calculate the execution statistics for the recipe")

        const oks = count(MessageStatus.ok);
        const errors = count(MessageStatus.error);
        const snippets = [...Object.keys(oks), ...Object.keys(errors)];

        return snippets.map((key) => {
            return {
                snippet: key.substring(0, 8),
                ok: oks[key] || 0,
                error: errors[key] || 0,
            } as ExecuteEntry;
        });
    }

    const errors = (): ErrorEntry[] => {
        console.log("Calculate the error statistics for the recipe")

        const errors = params.submissions.filter(submission => {
            const reply = submission.execute_reply.content as ExecuteReply;
            return reply.status === MessageStatus.error;
        });

        const stats = errors.reduce((result, submission) => {
            const reply = submission.execute_reply.content as ExecuteReply;
            result[reply.ename] = result[reply.ename] ? result[reply.ename] + 1 : 1;
            return result;
        }, {} as Record<string, number>);

        return Object.keys(stats).map((key) => {
            const entry = {
                id: key,
                label: key,
                value: stats[key],
            } as ErrorEntry;
            return entry;
        });
    };

    return (
        <>
            <Row style={{ height: "600px" }}>
                <Col>
                    <ResponsiveBar
                        data={executes()}
                        keys={["ok", "error"]}
                        indexBy="snippet"
                        margin={{ top: 60, right: 30, bottom: 60, left: 30 }}
                        padding={0.3}
                        colors={{ scheme: 'nivo' }}
                    />
                </Col>
                <Col>
                    <ResponsivePie
                        data={errors()}
                        margin={{ top: 60, right: 10, bottom: 60, left: 10 }}
                        innerRadius={0.5}
                        colors={{ scheme: 'nivo' }}
                        legends={[
                            {
                                anchor: "bottom",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemOpacity: 1,
                            }
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
}