import axios from "axios";
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { materialService } from "../service";
import { RegistrationActionType, RegistrationContext } from "./regcontext";

const RegistrationRemover: FunctionComponent = () => {
    const { store, dispatch } = useContext(RegistrationContext);

    const abort = useRef<AbortController>();

    const submit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch({ type: RegistrationActionType.START_SPIN });

        try {
            abort.current = new AbortController();
            const response = await materialService().delete(`/registration/${store.victim}`);

            switch (response.status) {
                case 200:
                    dispatch({ type: RegistrationActionType.HIDE_REMOVER });
                    dispatch({ type: RegistrationActionType.START_REFRESH });
                    break;
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                // this should be fine
            } else {
                console.log("Error found in account service request", error);
            }
        }

        dispatch({ type: RegistrationActionType.STOP_SPIN });
    };

    useEffect(() => {
        return () => {
            abort.current?.abort();
        }
    }, []);

    return (
        <Modal show={store.remover} >
            <Modal.Header closeButton>
                <Modal.Title>提示</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={submit} id="form">
                    即将删除编号为{store.victim}的注册。你确认吗？
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" type="submit" form="form">
                    {
                        store.spin && <Spinner as="span" size="sm" animation="border" variant="light" className="mr-2" />
                    }
                    确定
                </Button>

                <Button variant="outline-secondary" type="cancel" onClick={() => dispatch({ type: RegistrationActionType.HIDE_REMOVER })}>
                    取消
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export { RegistrationRemover };
