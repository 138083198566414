import axios, { CancelTokenSource } from "axios";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { accountService, TenantTransfer } from "../service";
import { SystemActionType, SystemNotifyAction } from "../store";
import { TenantName } from "./tenantname";

interface Params {
    code: string,
}

let source: CancelTokenSource;

/**
 * Panel to display basic information of the tenant.
 * 
 * Admin can modify the basic info here.
 */
export const TenantBasic: FunctionComponent<Params> = (params) => {
    const dispatch = useDispatch();

    const [tenant, setTenant] = useState<TenantTransfer>();
    const [name, setName] = useState<boolean>(false);

    const getTenant = useCallback(async () => {
        const source = axios.CancelToken.source();
        const response = await accountService().get(`/tenant/${params.code}`, {
            cancelToken: source.token,
        });

        switch (response.status) {
            case 200:
                setTenant(response.data as TenantTransfer);
                break;
            default:
                dispatch<SystemNotifyAction>({
                    type: SystemActionType.NOTIFY,
                    toast: true,
                    title: "🛸",
                    subtitle: new Date().toLocaleTimeString(),
                    content: `错误信息：${response.status} ${response.statusText}，请联系管理员。`,
                });
                break;
        }
    }, [params.code, dispatch])

    useEffect(() => {
        getTenant();

        return () => {
            source?.cancel();
        }
    }, [getTenant]);

    return (
        <>
            <Card className="mb-4">
                <Card.Body>
                    <Table borderless hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "25%" }}>课程信息</th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%" }}></th>
                                <th style={{ width: "25%", textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>编号</td>
                                <td>
                                    {tenant?.id}
                                </td>
                                <td>机构的内部编号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>代号</td>
                                <td>
                                    {tenant?.code}
                                </td>
                                <td>机构的独特代号</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>名称</td>
                                <td>
                                    {tenant?.fullname}
                                </td>
                                <td>机构的名称</td>
                                <td style={{ textAlign: "right", cursor: "pointer" }} onClick={() => setName(true)}>
                                    <span role="img" aria-label="edit" className="m-0 p-0">✏️</span>
                                    {
                                        tenant &&
                                        <TenantName
                                            show={name}
                                            tenant={tenant.code}
                                            fullname={tenant.fullname}
                                            onExit={() => { setName(false); getTenant(); }}
                                        />
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>时间</td>
                                <td>
                                    {tenant?.created}
                                </td>
                                <td>机构创建时间</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    );
}