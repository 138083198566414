import React, { FunctionComponent } from "react";
import { Container, Jumbotron, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CircleList } from "../circle";
import { TutorFooter } from "../navigator";
import { TenantBasic } from "./tenantbasic";

interface RouteParams {
    code: string
}

export const TenantDetail: FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <Jumbotron fluid>
                <Container>
                    <h1>机构</h1>
                </Container>
            </Jumbotron>
            <Container>
                <Tabs defaultActiveKey="basic" className="mb-4">
                    <Tab eventKey="basic" title="信息">
                        <TenantBasic
                            code={params.code}
                        />
                    </Tab>

                    <Tab eventKey="circle" title="班级">
                        <CircleList
                            tenant={params.code}
                        />
                    </Tab>
                </Tabs>

                <TutorFooter />
            </Container>
        </>
    );
}